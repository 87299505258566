<template>
  <a :href="urlHref" :target="blank && '__blank'" class="file-link">
    <span v-if="fileExtension" class="file-link__content-wrapper file-link__content-wrapper--icon">
      <span class="file-link__icon">
        <svg
          class="file-link__icon-svg"
          width="18"
          height="24"
          viewBox="0 0 18 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.50011 1C1.66813 1 1 1.66847 1 2.49739V10C1 10.167 0.918137 10.132 0.792353 10.0783C0.710135 10.0431 0.609152 10 0.5 10C0.390848 10 0.289865 10.0431 0.207647 10.0783C0.081863 10.132 0 10.167 0 10V2.49739C0 1.11631 1.11573 0 2.50011 0H11H11.5L13.4083 1.90833C13.7668 1.98197 14.1156 2.15968 14.4142 2.45827L15.5415 3.58554C15.8401 3.88413 16.0178 4.23295 16.0914 4.59143L18 6.5V7V21.4991C18 22.8819 16.8845 24 15.4999 24H2.50011C1.11697 24 0 22.8861 0 21.5V19C0 18.833 0.081863 18.868 0.207647 18.9217C0.289865 18.9569 0.390848 19 0.5 19C0.609152 19 0.710135 18.9569 0.792353 18.9217C0.918137 18.868 1 18.833 1 19V21.5C1 22.3332 1.66865 23 2.50011 23H15.4999C16.3317 23 17 22.3302 17 21.4991V7.20711L17.1464 7.35355L17 7V7.20711L15.835 6.04215C15.4988 6.59903 14.8958 6.99975 14.1273 6.99975H13C11.8954 6.99975 11 6.10432 11 4.99975V3.87248C11 3.104 11.4007 2.50097 11.9576 2.16471L10.7929 1H11L10.6464 0.853553L10.7929 1H2.50011Z"
            fill="#BEC5CD"
          />
        </svg>
        <span class="file-link__icon-extension">
          {{ fileExtension }}
        </span>
      </span>
    </span>
    <span class="file-link__content-wrapper file-link__content-wrapper--text">
      <span class="file-link__text">
        <slot></slot>
      </span>
    </span>
  </a>
</template>

<script>
import { isValidString, getRemoteFilePath } from '@/utils/helpers/string';

export default {
  name: 'FileLink',
  props: {
    blank: Boolean,
    extension: String,
    filePath: String,
    href: String,
    mimeType: String,
  },
  computed: {
    fileExtension() {
      if (isValidString(this.extension)) {
        return this.fileExtension;
      }
      let extension = '';
      let extensionMatch = null;
      // determine a source string to match for extension
      if (isValidString(this.mimeType)) {
        const { mimeType } = this;
        extensionMatch = mimeType.match(/(\/[\w]+)$/);
      } else {
        const { urlHref } = this;
        extensionMatch = urlHref.match(/(\.[\w]+)$/);
      }
      // retrieve extension from the match
      if (extensionMatch && extensionMatch.length && isValidString(extensionMatch[0])) {
        extension = extensionMatch[0].slice(1, extensionMatch[0].length);
      }
      return extension;
    },
    urlHref() {
      return this.href || getRemoteFilePath(this.filePath);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/base/_index';

.file-link {
  display: inline-flex;
  align-items: flex-end;
  &:hover {
    .file-link__text {
      opacity: 0.8;
    }
    .file-link__icon-extension {
      transform: translate(-84%, 50%) scale(1.2);
    }
  }
}
.file-link__content-wrapper {
  display: block;
}
.file-link__icon {
  display: block;
  width: 18px;
  height: 24px;
  position: relative;
  margin-right: 10px;
}
.file-link__icon-extension {
  display: inline-block;
  min-height: 9px;
  min-width: 20px;
  padding: 2px 3px;
  border-radius: 1px;
  background-color: #fcb13f;
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-70%, 50%);
  text-transform: uppercase;
  font-size: 6px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: $background-lightest;
  text-align: center;
  @include transition-all();
}
.file-link__text {
  font-size: 1rem;
  line-height: 1.2;
  color: $text-darkest;
  @include transition-all();
}
</style>
