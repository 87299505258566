var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"org-info",class:{ 'org-info--has-btns': _vm.hasButtons }},[_c('div',{staticClass:"org-info__wrapper"},[_c('div',{staticClass:"org-info__row"},[_c('div',{staticClass:"org-info__avatar"},[(_vm.logoPath.length)?_c('img',{attrs:{"src":_vm.getOrgLogoPath(_vm.logoPath)}}):_c('img',{attrs:{"src":require("@/assets/img/org-avatar-placeholder.svg"),"alt":"org img"}})])]),_c('div',{staticClass:"org-info__table-wrapper"},[_c('table',{staticClass:"org-info__table"},_vm._l((_vm.data),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index))]),(index === 'url')?_c('td',[_c('router-link',{staticClass:"org-info__table-link",attrs:{"to":{ path: ("/landing/" + item) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href,"target":"_blank"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.appLandingUrl)+_vm._s(item))])]}}],null,true)})],1):(index === 'files')?_c('td',_vm._l((item),function(ref,fileIndex){
var filename = ref.filename;
var mimetype = ref.mimetype;
var path = ref.path;
return _c('span',{key:(path + "_" + fileIndex),staticClass:"org-info__table-file-link-wrapper"},[_c('FileLink',{attrs:{"filePath":path,"mimeType":mimetype,"blank":""}},[_vm._v(" "+_vm._s(filename)+" ")])],1)}),0):_c('td',[(Array.isArray(item))?_vm._t("default",_vm._l((item),function(item,index){return _c('div',{key:index},[(typeof item === 'object')?_c('table',_vm._l((item),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(value))])])}),0):_c('div',[_vm._v(_vm._s(item))])])})):(typeof item === 'object')?_c('table',_vm._l((item),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(value))])])}),0):_vm._t("default",[_vm._v(_vm._s(item))])],2)])}),0)]),(_vm.hasButtons)?_c('div',{staticClass:"org-info__btns"},[_c('v-btn',{attrs:{"outlined":"","color":"primary","rounded":""},on:{"click":_vm.handleAddAdminClick}},[_vm._v("Add admins")]),_c('v-btn',{attrs:{"outlined":"","color":"primary","rounded":""},on:{"click":_vm.handleAddPolicyClick}},[_vm._v("Add policy information")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }