<!-- prettier-ignore -->
<template>
  <div :class="{ 'org-info--has-btns': hasButtons }" class="org-info">
    <div class="org-info__wrapper">
      <div class="org-info__row">
        <div class="org-info__avatar">
          <img
            v-if="logoPath.length"
            :src="getOrgLogoPath(logoPath)"
          />
          <img v-else src="@/assets/img/org-avatar-placeholder.svg" alt="org img" />
        </div>
      </div>
      <div class="org-info__table-wrapper">
        <table class="org-info__table">
          <tr v-for="(item, index) in data" :key="index">
            <td>{{ index }}</td>
            <td v-if="index === 'url'">
              <router-link
                v-slot="{ href, navigate }"
                :to="{ path: `/landing/${item}` }"
                class="org-info__table-link">
                <a @click="navigate" :href="href" target="_blank">{{ appLandingUrl }}{{ item }}</a>
              </router-link>
            </td>
            <td v-else-if="index === 'files'">
              <span v-for="({ filename, mimetype, path }, fileIndex) in item" :key="`${path}_${fileIndex}`" class="org-info__table-file-link-wrapper">
                <FileLink :filePath="path" :mimeType="mimetype" blank>
                  {{ filename }}
                </FileLink>
              </span>
            </td>
            <td v-else>
              <slot v-if="Array.isArray(item)">
                <div v-for="(item, index) of item" :key="index">
                  <table v-if="typeof item === 'object'">
                    <tr v-for="(value, key) in item" :key="key">
                      <td>{{ key }}</td>
                      <td>{{ value }}</td>
                    </tr>
                  </table>
                  <div v-else>{{ item }}</div>
                </div>
              </slot>
              <table v-else-if="typeof item === 'object'">
                <tr v-for="(value, key) in item" :key="key">
                  <td>{{ key }}</td>
                  <td>{{ value }}</td>
                </tr>
              </table>
              <slot v-else>{{ item }}</slot>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="hasButtons" class="org-info__btns">
        <v-btn @click="handleAddAdminClick" outlined color="primary" rounded>Add admins</v-btn>
        <v-btn @click="handleAddPolicyClick" outlined color="primary" rounded>Add policy information</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { env } from '@/utils/config';
import FileLink from '@/components/FileLink';
import { getRemoteFilePath } from '@/utils/helpers/string';

export default {
  name: 'OrgInfo',
  components: {
    FileLink,
  },
  props: {
    data: Object,
    orgLogo: [Object, String],
    type: String,
  },
  data() {
    return {
      appLandingUrl: `${env.APP_URL}landing/`,
    };
  },
  computed: {
    hasButtons() {
      const restricted = this.restrictedTypes;
      return restricted;
    },
    logoPath() {
      if (this.orgLogo && this.orgLogo.path) {
        return this.orgLogo.path;
      }
      return '';
    },
    restrictedTypes() {
      return this.type === 'brokerOrganization';
    },
  },
  methods: {
    getOrgLogoPath(path) {
      return getRemoteFilePath(path);
    },
    handleAddAdminClick() {
      this.$emit('inviteAdminClick');
    },
    handleAddPolicyClick() {
      this.$emit('addPolicyClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.org-info {
  min-height: 100%;
}

.org-info__wrapper {
  height: 100%;
}

.org-info__avatar {
  display: block;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: #fff;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.org-info__row {
  margin-bottom: 20px;
}

.org-info__table {
  width: 100%;
  tr {
    height: 30px;
    td {
      vertical-align: top;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: $text-darkest;
      padding-bottom: 12px;
      &:first-child {
        width: 30%;
        text-transform: capitalize;
        color: $text-semi-dark;
      }
      width: 60%;
    }
  }
}

.org-info__table-link {
  text-decoration: underline;
  cursor: pointer;
}
.org-info__table-file-link-wrapper {
  display: inline-block;
  margin-right: 16px;
  &:last-child {
    margin-right: 0px;
  }
}
</style>
